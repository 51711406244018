import React, { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { PiCurrencyInr } from "react-icons/pi";
import SyncLoader from "react-spinners/SyncLoader";
import FadeLoader from "react-spinners/FadeLoader";
import OTPInput, { ResendOTP } from "otp-input-react";
import { TiTickOutline } from "react-icons/ti";
import { BsCartCheckFill } from "react-icons/bs";
import moment, { now } from "moment";
import {
  handleCartCartInfoMinus,
  handleCartCartInfoPlus,
  handleCartMinus,
  handleCartPlus,
  handleFetchCategoryData,
  handleFetchProductsData,
  handleGetCartInfoStorageItems,
} from "../utilities/cartManager";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import { IoIosArrowDropupCircle } from "react-icons/io";
import "./customstyle.css";
import { AxiosError, getUserdata } from "../utilities/sessionexpiry";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoBagCheckOutline } from "react-icons/io5";
import { checkerArray, lowercasenosp } from "../utilities/checker";
import {
  AxiosDirectPost,
  AxiosInstamojoDirectPost,
  AxiosPost,
  FBConversionPUSH,
} from "../utilities/axioscall";
import { AK } from "../constants/AppKeys";
import { callStores, get, put } from "../utilities/storeManager";
import { SK } from "../constants/StorageKeys";
import { checkerString, checkerStringLen } from "../utilities/checker";
import { useDispatch } from "react-redux";
import { setUser } from "../store/user/actions";
import Footer from "./footer";
import NavbarMain from "./navbarmain";
import CheckBoxPage from "./checkbox";
import { handleGetCouponInfoStorageItems, handleGetInfoStorageItems, handleSetCouponInfoStorageItems, handleSetInfoStorageItems } from "../utilities/storageManager";

const meta = {
  title: "Order Success",
  meta: [],
  link: [],
  style: [],
  script: [],
};

export const Ordersuccess = (props) => {

  const {
    store,
    description,
    assets,
    storeid,
    hdimage,
    productimage,
    productviewimage,
    thumbnailviewimage,
    assetsUrl,
    categoryListData,
    userData,
    setUserData,
    versionmanagerListData,
    colorcodesListData,
    productscolorcodesListData,
    sizeListData,
    productssizeListData,
    productsListData,
    cartcouponlist,
    setcartcouponlist,
    cartinfoData,
    setCartInfoData,
    count,
    setCount,
    clearCartInfoData,
    pageRefresh,
    footercopyrighttext,
    showFooter,
    setshowFooter,
  } = props;
  const [storeinfo, setStoreInfo] = useState({
    storeid: null,
    store: "",
    title: "",
    description: "",
    assets: "",
    hdimage: "",
    productimage: "",
    productviewimage: "",
    thumbnailviewimage: "",
  });
  const navigate = useNavigate();
  const [pageinit, setPageInit] = useState(false);
  const [showFormLoader, setFormLoader] = useState(false);
  const [formdisplay, setFormDisplay] = useState([]);
  const [exdeliveryaddress, setExDeliveryAddress] = useState([]);
  const [exdeliveryaddshow, setExDeliveryAddshow] = useState(false);
  const [coffeeloader, setcoffeeloader] = useState(true);
  const [processingpaymentloader, setprocessingpaymentloader] = useState(false);
  const [showstatus, setShowstatus] = useState(0);

  const [pickdeliveryaddress, setpickdeliveryaddress] = useState("");
  const [timerresendsms, settimerresendsms] = useState(30);
  const [shippingrate, setShippingRate] = useState([]);
  const [shippingcalc, setShippingCalc] = useState(0);
  const [checkboxpayment, setcheckboxpayment] = useState(false);
  const [isCheckedWallet, setIsCheckedWallet] = useState(false);


  let formdisplayvalue = {
    loginorsignup: "loginorsignup",
    loginorsignupcontent: "loginorsignupcontent",
    verifywithotp: "verifywithotp",
    verifywithotpcontent: "verifywithotpcontent",
    checkoutsignin: "checkoutsignin",
    deliverycontent: "deliverycontent",
    paymentcontent: "paymentcontent",
    confirmcontent: "confirmcontent",
    ordersummarycontent: "ordersummarycontent",
  };
  // loginorsignup
  // loginorsignupcontent
  // verifywithotp
  // verifywithotpcontent
  // checkoutsignin
  // deliverycontent
  // paymentcontent
  // confirmcontent
  // ordersummarycontent

  const [checkoutDeliveryformData, setCheckoutDeliveryFormData] = useState({
    firstname: "",
    lastname: "",
    country: "",
    city: "",
    pincode: "",
    emailaddress: "",
    phonenumber: "",
    otpinput: "",
    doorno_streetaddress: "",
    location_town_district: "",
    primary_addr: 1,
    firstnameError: false,
    lastnameError: false,
    countryError: false,
    cityError: false,
    pincodeError: false,
    doorno_streetaddressError: false,
    location_town_districtError: false,
    emailaddressError: false,
    phonenumberError: false,
    otpinputError: false,
    otpinputformError: false,
    otpinputformErrorMsg: "Invalid OTP!",
    otploader: false,
    verifyotploader: false,
    loginorsignupformError: false,
    requestotploading: false,
    otpverifyloading: false,
    formError: false,
    formloading: false,
    formsuccessmsg: false,
    messageError: false,
    message: ""
  });

  const [checkoutPaymentformData, setCheckoutPaymentFormData] = useState({
    onlinepayment: false,
    banktransfer: false,
    upitransfer: false,
    razorpaytransfer: true,
    phonepetransfer: false,
    wallettransfer: false,
    phonepetransfer: false,
    onlinepaymentError: false,
    banktransferError: false,
    upitransferError: false,
    razorpaytransferError: false,
    phonepetransferError: false,
    requiredError: false,
    formError: false,
    formloading: false,
    formsuccessmsg: false,
  });


  // Enter Kay Function Start
  const [otpVerificationInitiated, setOtpVerificationInitiated] = useState(false);

  const handleVerifyOTP = async () => {
    if (await verifyotp()) {
      setFormDisplay([
        formdisplayvalue.checkoutsignin,
        formdisplayvalue.deliverycontent,
      ]);
      setCount({
        ...count,
        count: count + 1,
      });
      window.scrollTo(10, 0);
    }
  };

  const handleClick = async () => {
    if (await loginorsignup()) {
      setCount({
        ...count,
        count: count + 1,
      });
      setTimeout(() => {
        setFormDisplay([
          formdisplayvalue.verifywithotp,
          formdisplayvalue.verifywithotpcontent,
        ]);
        setCount({
          ...count,
          count: count + 1,
        });
        window.scrollTo(10, 0);
      }, 1000);
      setOtpVerificationInitiated(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!otpVerificationInitiated) {
        handleClick();
      } else {
        handleVerifyOTP();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, [otpVerificationInitiated]);

  // Enter Kay Function END

  const [orderdetails, setOrderDetails] = useState({});

  useEffect(() => {
    console.log("products : " + cartinfoData.total);
    setprocessingpaymentloader(false);
    if (cartinfoData.cartcount > 5) {
      console.log("***");
    } else {
      console.log("***");
    }
  }, [cartinfoData.cartcount, cartinfoData, cartinfoData.products]);

  useEffect(() => {

    console.log("***");
  }, [count]);



  const getcartCount = () => {
    // return cartinfoData.cartcount;
    return cartinfoData.cartcount;
  };


  const handleOrderPayment = async (directwallet = null) => {
    // if (!userData?.name) {
    //   navigate("/signin");
    //   return;
    // }
    // console.log("checkoutPaymentformData.wallettransfer")
    // console.log(checkoutPaymentformData.wallettransfer)
    setprocessingpaymentloader(true);

    // console.log("**********");
    //deliveryaddress: JSON.stringify(checkoutDeliveryformData),
    // cartinfoData['total'] = getfinalamount();
    console.log("getfinalamount()")
    console.log(getfinalamount(isCheckedWallet))
    let payload = {
      storeid: storeid,
      purpose: "purpose orderpayment",
      phone: checkoutDeliveryformData.phonenumber,
      buyer_name: checkoutDeliveryformData.firstname,
      redirect_url: "https://tamiltshirts.pages.dev/checkout",
      send_email: false,
      webhook: "https://tamiltshirts.pages.dev/checkout",
      send_sms: false,
      email: checkoutDeliveryformData.emailaddress,
      allow_repeated_payments: false,
      deliveryaddress: JSON.stringify(checkoutDeliveryformData),
      cartinfodata: JSON.stringify(cartinfoData),
      totalamount: getfinalamount(),
      amount: getfinalamount(),
      deliverychg: 0,
      name: checkoutDeliveryformData.firstname,
      mobile: checkoutDeliveryformData.phonenumber,
      Pending: "pending",
      shthd: "success",
      pmthd: "online",
      is_wallet: isCheckedWallet,
      wallet: getfinalamount()
    };




    // totalamount: getfinalamount(),
    //   amount: getfinalamount(),

    // let payload1 = {
    //   "storeid": "2",
    //   "purpose": "purpose orderpayment",
    //   "amount": 1,
    //   "phone": "8122512122",
    //   "buyer_name": "Testing",
    //   "redirect_url": "https://tamiltshirts.pages.dev/checkout",
    //   "send_email": false,
    //   "webhook": "https://tamiltshirts.pages.dev/checkout",
    //   "send_sms": false,
    //   "email": "rahman@vilvanetworks.com",
    //   "allow_repeated_payments": false,
    //   "deliveryaddress": "123 Main St, City, Country",
    //   "cartinfodata": "{\"cartcount\":1,\"cartquantitycount\":0,\"products\":[{\"imageurl\":\"t/m/tm-rn-tli-blk_1.jpg\",\"id\":1,\"storeid\":2,\"products_id\":1,\"category_id\":30,\"size_id\":\"1,2,3,4,5,6\",\"colorcodes_id\":8,\"weight_id\":0,\"products_group_id\":1,\"quantity\":100,\"mrp\":499,\"sp\":1,\"sortorder\":0,\"city_shipping\":null,\"state_shipping\":null,\"national_shipping\":null,\"delivery_time\":null,\"status\":\"001\",\"is_featured\":null,\"created_at\":\"2023-12-16 19:58:28\",\"updated_at\":\"2024-03-26 16:51:24\",\"customizable\":0,\"variation\":\"1,2\",\"name\":\"Tamizhi - Black\",\"eng_name\":null,\"sku\":\"TM-RN-TZ-Black\",\"descp\":\"<ul>\\n  <li>தமிழி(TAMIZHI)</li>\\n  <li>Bio Washed & Pre shrink</li>\\n  <li>Hand & Machine Wash</li>\\n  <li>7 days Return & Exchange Policy</li>\\n</ul>\\n\",\"shrtdesc\":\"\",\"seo_url\":\"tamizhi-black\",\"weight\":null,\"category_storeid\":2,\"category_subcat\":1,\"category_is_featured\":null,\"category_name\":\"ROUND NECK HALF SLEEVE TSHIRTS\",\"category_description\":null,\"category_seo_url\":null,\"category_meta_tag_title\":null,\"category_meta_tag_desc\":null,\"category_meta_tag_keywords\":null,\"category_cat_img\":null,\"category_parent_id\":18,\"category_sortorder\":0,\"category_status\":1,\"category_created_at\":\"2023-12-21 13:16:56\",\"category_updated_at\":\"2023-12-21 13:16:56\",\"images\":[\"t/m/tm-rn-tli-blk_1.jpg\"],\"selectedSizes\":[\"L\",\"L\"],\"addtocart\":true,\"cartquantity\":\"1\"}],\"cartprice\":0,\"subtotal\":1,\"mrptotal\":499,\"shipping\":0,\"ordertotal\":0,\"total\":1}",
    //   "totalamount": 1,
    //   "deliverychg": 49,
    //   "name": "Testing",
    //   "mobile": "8122512122",
    //   "Pending": "pending",
    //   "shthd": "success",
    //   "pmthd": "online"
    // }

    // payload = {
    //   storeid: storeid,
    //   amount: cartinfoData.total,
    //   deliveryaddress: "123 Main St, City, Country",
    //   cartinfodata: [
    //     {
    //       product_id: 1,
    //       quantity: 2,
    //     },
    //     {
    //       product_id: 2,
    //       quantity: 1,
    //     },
    //   ],
    //   totalamount: 1300,
    //   wallet: "wallet_id_123",
    //   deliverychg: 10,
    //   name: "Rahman",
    //   mobile: "1234567890",
    //   Pending: "pending_value",
    //   shthd: "shthd_value",
    //   pmthd: "pmthd_value",
    //   email: "rahman@vilvanetworks.com",
    // };

    // console.log(checkoutPaymentformData.banktransfer);
    // console.log(checkoutPaymentformData.upitransfer);
    // console.log(checkoutPaymentformData.onlinepayment);
    //https://mercury-t2.phonepe.com/transact/pg?token=MTA1ZTY4NGQyMmU2ZjI0NzA3OTAwNzAzMDUzNjUxMzllZTFlNjhiZWZlZjQ4NGM1MTg2YTBjZjhjMGNhNWYyYjQzOmI5YWRiOGRjM2Q3YjVlMTBhODg3Y2ZiYWU0ZjEzYTEz
    // console.log(AK.WALLETSUBMITAPI)

    // console.log(AK.WALLETSUBMITAPI)
    // console.log(checkoutPaymentformData.wallettransfer)

    await userCartInfoFetch();

    if (directwallet) {
      return await AxiosPost(AK.WALLETSUBMITAPI, payload, true)
        .then((res) => {
          // console.log(res?.data)
          if (res != typeof undefined && res.data != typeof undefined) {
            // console.log(res?.data)
            if (res?.data?.datas?.redirecturl != "") {
              // setUserData(userData?.wallet - getfinalamount())
              userData['wallet'] = userData?.wallet - getfinalamount()
              dispatch(setUser(userData));
              // clearCartInfoData();
              window.location.href = res?.data?.datas?.redirecturl;
              // window.location.replace(res.data.datas.redirecturl);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          // checkoutDeliveryformData.messageError = true;
          // checkoutDeliveryformData.message = error?.message;
          changeInput_CPFD(
            true,
            "messageError"
          );
          changeInput_CPFD(
            error?.response?.data?.message,
            "message"
          );
          setprocessingpaymentloader(false);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    }

    if (checkoutPaymentformData.phonepetransfer) {
      return await AxiosPost(AK.PHONEPEAPI, payload, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            console.log(res?.data)
            // console.log(res?.data?.data?.data?.payment_link);
             if (res?.data?.redirect_url != "") {
            //   clearCartInfoData();
             console.log(res?.data?.redirect_url)
             window.location.href = res?.data?.redirect_url;
                // window.location.replace(res?.data?.data?.data?.payment_link);
             }
          }
        })
        .catch((error) => {
          console.log(error);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    } else if (checkoutPaymentformData.upitransfer) {
      return await AxiosPost(AK.HAODAPAYUPI, payload, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            // console.log(res?.data?.data?.data?.payment_link);
            if (res?.data?.data?.data?.payment_link != "") {
              clearCartInfoData();
              window.location.href = res?.data?.data?.data?.payment_link;
              // window.location.replace(res?.data?.data?.data?.payment_link);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    } else if (checkoutPaymentformData.onlinepayment) {
      return await AxiosPost(AK.INSTAMOJOAPI, payload, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            if (res?.data?.datas?.redirecturl != "") {
              clearCartInfoData();
              window.location.href = res?.data?.datas?.redirecturl;
              // window.location.replace(res.data.datas.redirecturl);
            }
          }
        })
        .catch((error) => {
          console.log(error);
          setprocessingpaymentloader(false);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    } else if (checkoutPaymentformData.wallettransfer || checkoutPaymentformData.razorpaytransfer) {
      return await AxiosPost(AK.RAZORPAYAPI, payload, true)
        .then((res) => {
          if (res != typeof undefined && res.data != typeof undefined) {
            var rzpaykey = res.data.datas;
            var options = {
              key: rzpaykey["key"],
              amount: rzpaykey["amount"],
              currency: rzpaykey["currency"],
              name: rzpaykey["name"],
              description: rzpaykey["description"],
              image: rzpaykey["image"],
              order_id: rzpaykey["order_id"],
              callback_url: rzpaykey["callback_url"],
              prefill: {
                name: rzpaykey["prefill"]["name"],
                email: rzpaykey["prefill"]["email"],
                contact: rzpaykey["prefill"]["contact"],
              },
              notes: {
                address: rzpaykey["notes"]["address"],
              },
              theme: {
                color: rzpaykey["theme"]["color"],
              },
            };
            console.log(options);
            var rzp1 = new Razorpay(options);
            console.log(rzp1);
            // clearCartInfoData();
            rzp1.open();
            setprocessingpaymentloader(false)
            rzp1.on('payment.failed', function (response) {
              // alert(response.error.code);
              // alert(response.error.description);
              // alert(response.error.source);
              // alert(response.error.step);
              // alert(response.error.reason);
              // alert(response.error.metadata.order_id);
              // alert(response.error.metadata.payment_id);
            });

            // document.getElementById('positiveBtn').onclick = function (e) {
            //   window.location.href = window.location.host;
            //   e.preventDefault();
            // }

            // if (res?.data?.datas?.redirecturl != "") {
            //   clearCartInfoData();
            //   window.location.href = res?.data?.datas?.redirecturl;
            //   // window.location.replace(res.data.datas.redirecturl);
            // }
          }
        })
        .catch((error) => {
          console.log(error);
          setprocessingpaymentloader(false);
          changeInput_CPFD(false, "formloading");
          return false;
        });
    }
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const fetchOrderPayment = async () => {
    // if (!userData?.name) {
    //   navigate("/signin");
    //   return;
    // }

    let orderid = searchParams.get("orderid");
    if (!checkerString(orderid)) {
      return false;
    }

    let payload = {
      orderid: orderid.replace("ORDER", ""),
    };

    return await AxiosPost(AK.VIEWORDERBYIDAPI, payload, true)
      .then(async (res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          if (res?.data?.datas.length > 0) {
            // setFormDisplay([
            //   formdisplayvalue.checkoutsignin,
            //   formdisplayvalue.confirmcontent,
            // ]);
            setOrderDetails(res?.data?.datas[0]);
            await FBConversionPUSH('Order Successpage')
            clearCartInfoData();
            setCount({ ...count, count: count + 1 });
            return true;
          }
        }
      })
      .catch((error) => {
        return false;
      });
  };

  const fetchDeliveryAddress = async () => {
    // if (!userData?.name) {
    //   navigate("/signin");
    //   return;
    // }
    return await AxiosPost(AK.FETCHDELIVERYADDRESSAPI, {})
      .then((res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          if (res?.data?.datas.length > 0) {
            let _exadd = res?.data?.datas;
            console.log(res?.data)
            let _wallet = res?.data?.wallet?.toString();
            console.log(_wallet)
            console.log(userData)
            // Object.assign(userData, {wallet:  _wallet.toString()});
            //  var _userData = userData;
            // _userData['wallet'] =  _wallet.toString();
            // setUserData(_userData);
            // setUserData(...userData, {wallet:  _wallet.toString()});
            // dispatch(setUser(_userData));
            if (!_exadd[0]?.primary_addr) {
              _exadd[0]["primary_addr"] = 1;
            }

            // if (!_exadd[0]) {
            if (_exadd.length > 0) {
              setpickdeliveryaddress(_exadd[0]);
              setCheckoutDeliveryFormData(_exadd[0]);

              setFormDisplay([
                formdisplayvalue.checkoutsignin,
                formdisplayvalue.paymentcontent,
              ]);
              
            }

            console.log(_exadd);

            // }

            setExDeliveryAddress(_exadd);
            setExDeliveryAddshow(true);

            setCount({ ...count, count: count + 1 });
            return true;
          }
        }
        setExDeliveryAddshow(false);

        return true;
      })
      .catch((error) => {
        console.log("***error***")
        console.log(error)
        return false;
      });
  };

  let dispatch = useDispatch();

  const getfinalamount = (damt = false) => {
    if (damt) {
      return userData?.wallet > (cartinfoData.total + shippingcalc) ? 0 : (cartinfoData.total + shippingcalc) - userData?.wallet;
    } else {
      return cartinfoData.total + shippingcalc;
    }
    // return cartinfoData.total + shippingcalc;
  }

  const loginorsignup = async () => {
    let error = false;
    changeInput(false, "phonenumberError");

    if (!checkerString(checkoutDeliveryformData.phonenumber)) {
      error = true;
      changeInput(true, "phonenumberError");
    }

    if (!checkerStringLen(checkoutDeliveryformData.phonenumber, 10)) {
      error = true;
      changeInput(true, "phonenumberError");
    }

    var phoneno = /^\d{10}$/;
    if (!checkoutDeliveryformData.phonenumber.match(phoneno)) {
      error = true;
      changeInput(true, "phonenumberError");
    }

    if (error) return false;

    const requestotppayload = {
      mobile: checkoutDeliveryformData.phonenumber,
      storeid: storeid,
    };
    await changeInput(true, "otploader");
    await changeInput(false, "requestotploading");
    return await AxiosPost(AK.REQUESTOTPAPI, requestotppayload, false)
      .then(async (res) => {
        changeInput(true, "requestotploading");

        if (res != typeof undefined && res.data != typeof undefined) {
          dispatch(setUser(null));
          // console.log(res.data);
          return true;
        }
      })
      .catch(async (error) => {
        let errors = AxiosError(error);
        await changeInput(false, "otploader");
        await changeInput(true, "otpinputformError");
        return false;
      });
  };

  const verifyotp = async () => {
    let error = false;

    changeInput(false, "otpinputError");

    if (!checkerString(checkoutDeliveryformData.otpinput)) {
      error = true;
      changeInput(true, "otpinputError");
    }

    if (!checkerStringLen(checkoutDeliveryformData.otpinput, 4)) {
      error = true;
      changeInput(true, "otpinputError");
    }

    if (error) return false;
    const requestotppayload = {
      mobile: checkoutDeliveryformData.phonenumber,
      otp: checkoutDeliveryformData.otpinput,
    };
    await changeInput(true, "verifyotploader");
    await changeInput(false, "requestotploading");
    return await AxiosPost(AK.VERIFYOTPAPI, requestotppayload, false)
      .then(async (res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          changeInput(true, "requestotploading");
          let userdata = JSON.parse(JSON.stringify(res.data));
          //console.log(userdata);
          setcoffeeloader(true);
          dispatch(setUser(userdata));
          setUserData(userData);
          setCount({ ...count, count: count + 1 });
          await fetchDeliveryAddress();
          setcoffeeloader(false);
          setCount({ ...count, count: count + 1 });
          pageRefresh();
          // window.location.reload();
          return true;
        }
      })
      .catch(async (error) => {
        console.log(error);
        let errors = AxiosError(error);
        await changeInput(errors?.message, "otpinputformErrorMsg");
        await changeInput(false, "verifyotploader");
        await changeInput(true, "otpinputformError");
        return false;
      });
  };

  const handleDeliveryAddress = async () => {
    // if (!userData?.name) {
    //   navigate("/signin");
    //   return;
    // }

    let error = false;

    changeInput(false, "firstnameError");
    changeInput(false, "lastnameError");
    changeInput(false, "countryError");
    changeInput(false, "cityError");
    changeInput(false, "pincodeError");
    changeInput(false, "doorno_streetaddressError");
    changeInput(false, "location_town_districtError");
    changeInput(false, "emailaddressError");
    changeInput(false, "phonenumberError");

    if (!checkerString(checkoutDeliveryformData.firstname)) {
      error = true;
      changeInput(true, "firstnameError");
    }

    if (!checkerString(checkoutDeliveryformData.lastname)) {
      error = true;
      changeInput(true, "lastnameError");
    }

    if (!checkerString(checkoutDeliveryformData.country)) {
      error = true;
      changeInput(true, "countryError");
    }

    if (!checkerString(checkoutDeliveryformData.city)) {
      error = true;
      changeInput(true, "cityError");
    }

    if (!checkerString(checkoutDeliveryformData.pincode)) {
      error = true;
      changeInput(true, "pincodeError");
    }

    if (!checkerString(checkoutDeliveryformData.doorno_streetaddress)) {
      error = true;
      changeInput(true, "doorno_streetaddressError");
    }

    if (!checkerString(checkoutDeliveryformData.location_town_district)) {
      error = true;
      changeInput(true, "location_town_districtError");
    }

    if (!checkerString(checkoutDeliveryformData.phonenumber)) {
      error = true;
      changeInput(true, "phonenumberError");
    }
    if (!checkerString(checkoutDeliveryformData.emailaddress)) {
      error = true;
      changeInput(true, "emailaddressError");
    } else {
      var emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(checkoutDeliveryformData.emailaddress)) {
        error = true;
        changeInput(true, "emailaddressError");
      }
    }
    var phoneno = /^\d{10}$/;
    if (!checkoutDeliveryformData.phonenumber.match(phoneno)) {
      error = true;
      changeInput(true, "phonenumberError");
    }

    if (error) return false;

    if (!checkoutDeliveryformData["primary_addr"])
      checkoutDeliveryformData["primary_addr"] = 1;

    checkoutDeliveryformData["primary_addr"] = !checkoutDeliveryformData[
      "primary_addr"
    ]
      ? 1
      : checkoutDeliveryformData["primary_addr"] === "HOME"
        ? 1
        : checkoutDeliveryformData["primary_addr"] === "OFFICE"
          ? 2
          : checkoutDeliveryformData["primary_addr"] === "OTHER"
            ? 3
            : 1;

    put(SK.DELIVERYCONTENTINFODATA, checkoutDeliveryformData);

    return await AxiosPost(
      AK.SUBMITDELIVERYADDRESSAPI,
      checkoutDeliveryformData
    )
      .then(async (res) => {
        console.log(res);
        if (res != typeof undefined && res.data != typeof undefined) {
          console.log(res);
          setExDeliveryAddress(res?.data?.datas);
          if (res?.data?.datas.length > 0) {
            setpickdeliveryaddress(res?.data?.datas[0]);
            setCheckoutDeliveryFormData(res?.data?.datas[0]);
          }
          //setUserData(userData);
          setCount({ ...count, count: count + 1 });

          // pageRefresh();
          return true;
        }
      })
      .catch(async (error) => {
        console.log(error);
        let errors = AxiosError(error);

        return false;
      });
  };

  var timeLeft = 30;

  //var elem = document.getElementById("some_div");

  const countdown = () => {
    if (timeLeft == -1) {
      clearTimeout(timerId);
      //  doSomething();
    } else {
      // elem.innerHTML = timeLeft + " seconds remaining";
      timeLeft--;
      settimerresendsms(timeLeft);
    }
  };

  var timerId = setInterval(countdown, 1000);

  const doSomething = () => {
    // alert("Hi");
  };

  const changeInput = useCallback(async (value, field) => {
    checkoutDeliveryformData[field] = value;
    setCheckoutDeliveryFormData(checkoutDeliveryformData);
    setCount((count) => (count = count + 1));
  });

  const changeInput_CPFD = useCallback(async (value, field) => {
    checkoutPaymentformData[field] = value;
    setCheckoutPaymentFormData(checkoutPaymentformData);
    setCount((count) => (count = count + 1));
  });

  // const userCartInfoFetch = async () => {
  //   AxiosPost(
  //     AK.USERCARTINFOAPI, JSON.stringify(cartinfoData),
  //     true
  //   ).then((res) => {
  //     if (res != typeof undefined && res.data != typeof undefined) {
  //       console.log(res.data)
  //       // setCartInfoData(_cartinfo);
  //       // setCount({ ...count, count: count + 1 });
  //     }
  //   })
  //     .catch((error) => {
  //       console.log(error);
  //       return false;
  //     });
  // }

  const userCartInfoFetch = async () => {
    //console.log(cartinfoData)
    if (cartinfoData.products.length > 0) {
      AxiosPost(
        AK.USERCARTINFOFETCHPUSHAPI, cartinfoData,
        true
      ).then((res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          console.log(res.data)
          // setCartInfoData(res.data);
          // setCount({ ...count, count: count + 1 });
        }
      })
        .catch((error) => {
          console.log(error);
          return false;
        });
    } else {
      AxiosPost(
        AK.USERCARTINFOAPI, {},
        true
      ).then((res) => {
        if (res != typeof undefined && res.data != typeof undefined) {
          console.log(res.data)
          setCartInfoData(res?.data?.cartinfo);
          handleSetCartInfoStorageItems(res?.data?.cartinfo)
          setCount({ ...count, count: count + 1 });
        }
      })
        .catch((error) => {
          console.log(error);
          return false;
        });
    }

  }

  const userCartInfoFetchClear = async () => {
    AxiosPost(
      AK.USERCARTINFOFETCHCLEARAPI, JSON.stringify(cartinfoData),
      true
    ).then((res) => {
      if (res != typeof undefined && res.data != typeof undefined) {
        console.log(res.data)
        // setCartInfoData(_cartinfo);
        // setCount({ ...count, count: count + 1 });
      }
    })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  const callinitfetching = async (oneloader = true) => {
    setcoffeeloader(true);
    // setCount({ ...count, count: count + 1 });
    // let _cartinfo = await handleGetCartInfoStorageItems(setCartInfoData);

    // if (oneloader) {

      if (await fetchOrderPayment()) {
        setcoffeeloader(false);
        //  navigate("/orderhistory")
      }
      
      // else {

      //   if (_cartinfo?.cartcount === 0) {
      //     navigate("/cart");
      //   }
      //   await fetchDeliveryAddress();
      //   await pageRefresh();
      // }

    // } 
    
    // else {
    //   await pageRefresh();
    // }

    let _datas = [];

    // _cartinfo?.products.map(prod => {
    //   _datas.push({ productid: prod.products_id, weight: prod.weight })
    // })

    // await userCartInfoFetch();

    // AxiosPost(
    //   AK.CALCULATESHIPPINGCHARGEAPI,
    //   {
    //     storeid: storeid,
    //     datas: _datas,
    //   },
    //   true
    // )
    //   .then((res) => {
    //     if (res != typeof undefined && res.data != typeof undefined) {
    //       let calcdata = res.data;
    //       // console.log(calcdata)

    //       if (checkerArray(res.data)) {
    //         calcdata[0]['checked'] = true;
    //         setShippingCalc(calcdata[0]?.shipping);
    //         _cartinfo['shippingrate'] = calcdata[0]?.method + "   ₹" + calcdata[0]?.shipping;
    //       } else {
    //         calcdata['checked'] = true;
    //         setShippingCalc(calcdata?.shipping);
    //         _cartinfo['shippingrate'] = calcdata?.method + "   ₹" + calcdata?.shipping;

    //       }

    //       setShippingRate(calcdata);
    //       setCartInfoData(_cartinfo);
    //       setCount({ ...count, count: count + 1 });
    //       console.log(shippingrate)

    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     return false;
    //   });

    setcoffeeloader(false);
    setCount({ ...count, count: count + 1 });
  };

  const callpageInit = async () => {
    let version = handleGetInfoStorageItems(SK.VERSIONINFODATA);
    let hardreset = false;
    let mockupdata = false;
    if (
      version === null ||
      version === undefined ||
      isNaN(Number(version)) ||
      Number(version) === null ||
      Number(version) === undefined ||
      Number(process.env.REACT_APP_VERSION) !== Number(version)
    ) {
      // console.log("mockup true");
      handleSetInfoStorageItems(
        SK.VERSIONINFODATA,
        Number(process.env.REACT_APP_VERSION)
      );
      setCount({ ...count, count: count + 1 });
      mockupdata = true;
    }

    // setAssetsUrl(await callStores("assets", hardreset, true));
    let storedetails = await callStores(null, hardreset, mockupdata);
    // setStoreInfo(storedetails);
    // console.log(storedetails)
    const shippingConfig = storedetails.shipping_config;
    const statusvalue = JSON.parse(shippingConfig).methods[0].status;

    console.log("status" + statusvalue); // Output: "1"
    setShowstatus(statusvalue);
  }

  if (!pageinit) {
    callpageInit()
    setUserData(getUserdata());
    setCount({ ...count, count: count + 1 });
    console.log("...formloading...");
    FBConversionPUSH('Checkout')
    setcartcouponlist(handleGetCouponInfoStorageItems());

    if (getUserdata()?.mobile) {
      // setFormDisplay([
      //   formdisplayvalue.checkoutsignin,
      //   formdisplayvalue.deliverycontent,
      // ]);
      // checkoutDeliveryformData.firstname = userData?.name;
      // checkoutDeliveryformData.emailaddress = userData?.email;
      // let deliverycontent = get(SK.DELIVERYCONTENTINFODATA);
      // if (deliverycontent?.firstname) {
      //   setCheckoutDeliveryFormData(deliverycontent);
      // } else {
      //   setCheckoutDeliveryFormData(checkoutDeliveryformData);
      // }

      callinitfetching();

      setPageInit(true);



      // console.log(userData?.wallet);

      return;
    } else {
      setFormDisplay([
        formdisplayvalue.loginorsignup,
        formdisplayvalue.loginorsignupcontent,
      ]);
      setCount({ ...count, count: count + 1 });
      callinitfetching(false);
      setPageInit(true);
    }
    // console.log(shippingrate)
    // changeInput_CPFD(true, "onlinepayment");
    // changeInput_CPFD(false, "banktransfer");
    // changeInput_CPFD(false, "upitransfer");
  }

    
  return (
    <React.Fragment>
    <HelmetProvider>
    <Helmet {...meta}>
      <script src="https://checkout.razorpay.com/v1/checkout.js" defer></script>
    </Helmet>
  </HelmetProvider>
  <>
  <section className="relative">
          <nav
            style={{ fontWeight: 500, fontSize: "13px" }}
            className="flex justify-between border-b navcustombg"
          >
            <div className="px-6 py-2 flex w-full items-center">
              {/* <a className="hidden xl:block mr-16" href='javascript:;'>
                <svg
                  width={18}
                  height={18}
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M18 15.4688H0V17.7207H18V15.4688Z" fill="black" />
                  <path
                    d="M11.0226 7.87402H0V10.126H11.0226V7.87402Z"
                    fill="black"
                  />
                  <path d="M18 0.279297H0V2.53127H18V0.279297Z" fill="black" />
                </svg>
              </a> */}
              <ul className="hidden xl:flex font-heading">
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/men">
                    MEN
                  </a>
                </li>
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/women">
                    WOMEN
                  </a>
                </li>
                <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products/kids">
                    KIDS
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    className="hover:text-gray-600"
                    href="/products/couplecollection"
                  >
                    COUPLE COLLECTION
                  </a>
                </li>
                {/* <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products">
                    Apparels
                  </a>
                </li> */}
                <li>
                  <a
                    className="hover:text-gray-600"
                    href="/products/politicalshirts"
                  >
                    POLITICAL SHIRTS
                  </a>
                </li>
              </ul>
              <a
                className="flex-shrink-0 xl:mx-auto text-3xl font-bold font-heading"
                href="/"
              >
                <img
                  style={{ height: "38px" }}
                  className="h-12"
                  src={`/yofte-assets/logos/${lowercasenosp(store)}/logo.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              {/* <div className="hidden xl:inline-block mr-14">
                <input
                  className="py-5 px-8 w-full placeholder-gray-400 text-xs uppercase font-semibold font-heading bg-gray-50 border border-gray-200 focus:ring-blue-300 focus:border-blue-300 rounded-md"
                  type="text"
                  placeholder="Search"
                />
              </div> */}

              <div className="hidden xl:flex items-center">
                {/* <div className="pull-right mainHeaderCols activemenuwrp">
                  <div className="actionMenu" style={{ padding: "15px" }}>
                    <span
                      className="actionMenu actionMenuInner"
                      id="testHeaderCart"
                    >
                      <a
                        href="/cart"
                        className="cartIcon"
                        style={{ paddingRight: 16, position: "relative" }}
                      >
                        <i className="icon_bag" aria-hidden="true" />
                        <span className="cartCount"> {getcartCount()}</span>
                      </a>
                    </span>
                    <span
                      className="actionMenu"
                      id="testHeadWish"
                      style={{ padding: "0px 5px", cursor: "pointer" }}
                    >
                      <div>
                        <div className="">
                          <i
                            className="icon_wishlist"
                            style={{ fontSize: "20px" }}
                          />
                        </div>
                      </div>
                    </span>
                    <a href="#" id="testHeaderAcc">
                      <i className="icon_user" />
                    </a>
                  </div>
                </div> */}

                <a className="mr-2 hover:text-gray-600" href="#">
                  <svg
                    width={23}
                    height={20}
                    viewBox="0 0 23 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.4998 19.2061L2.70115 9.92527C1.92859 9.14433 1.41864 8.1374 1.24355 7.04712C1.06847 5.95684 1.23713 4.8385 1.72563 3.85053V3.85053C2.09464 3.10462 2.63366 2.45803 3.29828 1.96406C3.9629 1.47008 4.73408 1.14284 5.5483 1.00931C6.36252 0.875782 7.19647 0.939779 7.98144 1.19603C8.7664 1.45228 9.47991 1.89345 10.0632 2.48319L11.4998 3.93577L12.9364 2.48319C13.5197 1.89345 14.2332 1.45228 15.0182 1.19603C15.8031 0.939779 16.6371 0.875782 17.4513 1.00931C18.2655 1.14284 19.0367 1.47008 19.7013 1.96406C20.3659 2.45803 20.905 3.10462 21.274 3.85053V3.85053C21.7625 4.8385 21.9311 5.95684 21.756 7.04712C21.581 8.1374 21.071 9.14433 20.2984 9.92527L11.4998 19.2061Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </a>
                <a
                  className="flex items-center hover:text-gray-600"
                  href="/cart"
                >
                  {/* <svg
                    className="mr-3"
                    width={23}
                    height={23}
                    viewBox="0 0 23 23"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span
                    style={{ padding: "3px" }}
                    className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
                  >
                    {getcartCount()}
                  </span> */}
                  <div className="pull-right mainHeaderCols activemenuwrp">
                    <div className="actionMenu" style={{ padding: "10px" }}>
                      <span
                        className="actionMenu actionMenuInner"
                        id="testHeaderCart"
                      >
                        <a
                          href="/cart"
                          className="cartIcon"
                          style={{ paddingRight: 16, position: "relative" }}
                        >
                          <i className="icon_bag" aria-hidden="true" />
                          <span className="cartCount"> {getcartCount()}</span>
                        </a>
                      </span>
                      {/* <span
                        className="actionMenu"
                        id="testHeadWish"
                        style={{ padding: "0px 5px", cursor: "pointer" }}
                      >
                        <div>
                          <div className="">
                            <i
                              className="icon_wishlist"
                              style={{ fontSize: "20px" }}
                            />
                          </div>
                        </div>
                      </span>
                      <a href="#" id="testHeaderAcc">
                        <i className="icon_user" />
                      </a> */}
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div className="hidden xl:flex items-center px-12 border-l font-heading hover:text-gray-600">
              <a
                href="/signin"
                style={{
                  display: userData != null ? "none" : "flex",
                }}
              >
                <svg
                  className="mr-3"
                  width={32}
                  height={31}
                  viewBox="0 0 32 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ width: "150px", paddingTop: "5px" }}>
                  SIGN&nbsp;IN
                </span>
              </a>

              <a
                className="dropdown"
                href={() => {
                  return false;
                }}
                style={{
                  display: userData === null ? "none" : "flex",
                  cursor: "pointer",
                }}
              >
                <svg
                  className="mr-3"
                  width={32}
                  height={31}
                  viewBox="0 0 32 31"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <span style={{ width: "150px", paddingTop: "5px" }}>
                  {userData?.name}
                </span>
                <div className="dropdown-content">
                  <a href="/myprofile">My Profile</a>
                  <a href="/orderhistory" className="active">
                    My Orders
                  </a>
                  <a href="/changepassword">Change Password</a>
                  <hr
                    style={{
                      border: "1px solid #CFD5E2",
                      height: "2px",
                    }}
                  />
                  <a href="/signin">Logout</a>
                </div>
              </a>
            </div>

            <a
              className="xl:hidden flex mr-6 items-center text-gray-900"
              href="/cart"
            >
              {/* <svg
                className="mr-2"
                width={23}
                height={23}
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.1159 8.72461H2.50427C1.99709 8.72461 1.58594 9.12704 1.58594 9.62346V21.3085C1.58594 21.8049 1.99709 22.2074 2.50427 22.2074H18.1159C18.6231 22.2074 19.0342 21.8049 19.0342 21.3085V9.62346C19.0342 9.12704 18.6231 8.72461 18.1159 8.72461Z"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6.34473 6.34469V4.95676C6.34473 3.85246 6.76252 2.79338 7.5062 2.01252C8.24988 1.23165 9.25852 0.792969 10.3102 0.792969C11.362 0.792969 12.3706 1.23165 13.1143 2.01252C13.858 2.79338 14.2758 3.85246 14.2758 4.95676V6.34469"
                  stroke="currentColor"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span
                style={{ padding: "3px" }}
                className="inline-block w-6 h-6 text-center text-white bg-orange-300  hover:bg-orange-400 rounded-full font-heading"
              >
                {getcartCount()}
              </span> */}
              <div className="pull-right mainHeaderCols activemenuwrp">
                <div className="actionMenu" style={{ padding: "10px" }}>
                  <span
                    className="actionMenu actionMenuInner"
                    id="testHeaderCart"
                  >
                    <a
                      href="/cart"
                      className="cartIcon"
                      style={{ paddingRight: 16, position: "relative" }}
                    >
                      <i className="icon_bag" aria-hidden="true" />
                      <span className="cartCount"> {getcartCount()}</span>
                    </a>
                  </span>
                  {/* <span
                        className="actionMenu"
                        id="testHeadWish"
                        style={{ padding: "0px 5px", cursor: "pointer" }}
                      >
                        <div>
                          <div className="">
                            <i
                              className="icon_wishlist"
                              style={{ fontSize: "20px" }}
                            />
                          </div>
                        </div>
                      </span>
                      <a href="#" id="testHeaderAcc">
                        <i className="icon_user" />
                      </a> */}
                </div>
              </div>
            </a>
            <a
              className="navbar-burger self-center mr-12 xl:hidden"
              href={() => {
                return false;
              }}
              onClick={() => {
                document.getElementById("mySidenav").style.width = "290px";
              }}
            >
              <svg
                width={20}
                height={12}
                viewBox="0 0 20 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 2H19C19.2652 2 19.5196 1.89464 19.7071 1.70711C19.8946 1.51957 20 1.26522 20 1C20 0.734784 19.8946 0.48043 19.7071 0.292893C19.5196 0.105357 19.2652 0 19 0H1C0.734784 0 0.48043 0.105357 0.292893 0.292893C0.105357 0.48043 0 0.734784 0 1C0 1.26522 0.105357 1.51957 0.292893 1.70711C0.48043 1.89464 0.734784 2 1 2ZM19 10H1C0.734784 10 0.48043 10.1054 0.292893 10.2929C0.105357 10.4804 0 10.7348 0 11C0 11.2652 0.105357 11.5196 0.292893 11.7071C0.48043 11.8946 0.734784 12 1 12H19C19.2652 12 19.5196 11.8946 19.7071 11.7071C19.8946 11.5196 20 11.2652 20 11C20 10.7348 19.8946 10.4804 19.7071 10.2929C19.5196 10.1054 19.2652 10 19 10ZM19 5H1C0.734784 5 0.48043 5.10536 0.292893 5.29289C0.105357 5.48043 0 5.73478 0 6C0 6.26522 0.105357 6.51957 0.292893 6.70711C0.48043 6.89464 0.734784 7 1 7H19C19.2652 7 19.5196 6.89464 19.7071 6.70711C19.8946 6.51957 20 6.26522 20 6C20 5.73478 19.8946 5.48043 19.7071 5.29289C19.5196 5.10536 19.2652 5 19 5Z"
                  fill="#8594A5"
                />
              </svg>
            </a>
            <div id="mySidenav" className="sidenav mr-12 xl:hidden">
              <a
                href={() => {
                  return false;
                }}
                className="closebtn"
                onClick={() => {
                  document.getElementById("mySidenav").style.width = "0";
                }}
              >
                &times;
              </a>
              <div className="xl:flex items-center border-l font-heading hover:text-gray-600">
                <a
                  href="/signin"
                  style={{
                    display: userData != null ? "none" : "flex",
                  }}
                >
                  <svg
                    className="mr-3"
                    width={32}
                    height={31}
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ width: "150px", paddingTop: "5px" }}>
                    SIGN&nbsp;IN
                  </span>
                </a>

                <a
                  className="dropdown"
                  href="#"
                  style={{
                    display: userData === null ? "none" : "flex",
                    cursor: "pointer",
                  }}
                >
                  <svg
                    className="mr-3"
                    width={32}
                    height={31}
                    viewBox="0 0 32 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.0006 16.3154C19.1303 16.3154 21.6673 13.799 21.6673 10.6948C21.6673 7.59064 19.1303 5.07422 16.0006 5.07422C12.871 5.07422 10.334 7.59064 10.334 10.6948C10.334 13.799 12.871 16.3154 16.0006 16.3154Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M24.4225 23.8963C23.6678 22.3507 22.4756 21.0445 20.9845 20.1298C19.4934 19.2151 17.7647 18.7295 15.9998 18.7295C14.2349 18.7295 12.5063 19.2151 11.0152 20.1298C9.52406 21.0445 8.33179 22.3507 7.57715 23.8963"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span style={{ width: "150px", paddingTop: "0px" }}>
                    {userData?.name}
                  </span>
                  <div className="dropdown-content-nav">
                    <a href="/myprofile">My Profile</a>
                    <a href="/orderhistory" className="active">
                      My Orders
                    </a>
                    <a href="/changepassword">Change Password</a>
                    <hr
                      style={{
                        border: "1px solid #CFD5E2",
                        height: "2px",
                      }}
                    />
                    <a href="/signin">Logout</a>
                  </div>
                </a>
              </div>
              <hr
                style={{
                  border: "1px solid #CFD5E2",
                  height: "2px",
                }}
              />
              <ul className="xl:flex" style={{ fontSize: "6px" }}>
                <li className="mr-12 ">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/men"
                  >
                    🕺 &nbsp;&nbsp; Men
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/women"
                  >
                    💃 &nbsp;&nbsp; Women
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/kids"
                  >
                    👶 &nbsp;&nbsp; Kids
                  </a>
                </li>
                <li className="mr-12">
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/couplecollection"
                  >
                    👩‍❤️‍👨 &nbsp;&nbsp; Couple Collection
                  </a>
                </li>
                {/* <li className="mr-12">
                  <a className="hover:text-gray-600" href="/products">
                    Apparels
                  </a>
                </li> */}
                <li>
                  <a
                    style={{ display: "flex" }}
                    className="hover:text-gray-600"
                    href="/products/politicalshirts"
                  >
                    🚩 &nbsp;&nbsp; Political Shirts
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </section>
    <div>   <div className="w-full mb-5">
    <br />
    <br />
    <center>
      <img
        style={{ width: "auto" }}
        src={`yofte-assets/loader/order-placed.webp`}
        alt={`${lowercasenosp(store)} loader`}
      />
      {/* <BsCartCheckFill
        color="#7fa37f"
        style={{
          height: "100px",
          width: "100px",
        }}
      /> */}
      <br />
      <h3>
        <b>Thank you for shopping!</b>
      </h3>
      <p>
        Your order has been placed. <br />
      </p>
      <br />
      <p>
        Total Paid: ₹ {orderdetails.totalamt}
        <br />
      </p>
      <br />
      <button style={{ backgroundColor: '#86BC42' }}
        className="block w-72 py-2  text-center text-white font-heading uppercase rounded-md transition duration-200"
        onClick={() => {
          // setFormDisplay([
          //   formdisplayvalue.checkoutsignin,
          //   formdisplayvalue.ordersummarycontent,
          // ]);
          navigate("/orderhistory");
          setCount({
            ...count,
            count: count + 1,
          });
          window.scrollTo(10, 0);
        }}
      >
        View Order
      </button>
    </center>
  </div></div>
  </>
  <section className="py-4 bg-blue-300 footerStyle">
          <div className="container mx-auto px-2">
            <div className="flex flex-wrap -mx-4 pb-6 lg:pb-6 border-b border-gray-400">
              <div className="w-full lg:w-3/5 px-4 mb-6">
                <div className="flex flex-wrap -mx-4">
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-2 text-xl font-bold font-heading text-white">
                      Information
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Newsroom
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Story
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Yofte Careers
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Investor Relations
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Reward program
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Delivery information
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Paying by invoice
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-10 lg:mb-0">
                    <h3 className="mb-2 text-xl font-bold font-heading text-white">
                      Customer Service
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Return an order
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Search Terms
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Advanced Search
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Orders and Returns
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          FAQs
                        </a>
                      </li>
                      <li className="mb-2">
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Store Location
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-gray-50 hover:text-gray-200"
                          href={() => {
                            return false;
                          }}
                        >
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="w-full md:w-1/2 lg:w-1/3 px-4 mb-4">
                    <h3 className="mb-2 text-xl text-white font-bold font-heading">
                      Contact Us
                    </h3>
                    <ul>
                      <li className="mb-2">
                        <h4 className="mb-2 text-gray-50">Mobile</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          +91 95 517 89459
                        </a>
                      </li>
                      <li className="mb-2">
                        <h4 className="mb-2 text-gray-50">Email</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          hi@teemania.com
                        </a>
                      </li>
                      <li>
                        <h4 className="mb-2 text-gray-50">Live Chat</h4>
                        <a
                          className="text-white hover:underline"
                          href={() => {
                            return false;
                          }}
                        >
                          teemania
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="w-full lg:w-2/5 px-4 order-first lg:order-1 mb-2">
                <h3 className="mb-2 text-xl text-white font-bold font-heading">
                  Join our Newsletter
                </h3>
                <p className="mb-2 text-xl text-yellow-500 font-bold font-heading">
                  News, sales:
                </p>
                <div className="mb-2 relative lg:max-w-xl lg:mx-auto bg-white rounded-lg">
                  <div className="relative flex flex-wrap items-center justify-between">
                    <div className="relative flex-1">
                      {/* <span className="absolute top-0 left-0 ml-8 mt-4 font-semibold font-heading text-xs text-gray-400">
                      Drop your e-mail
                    </span> */}
                      <input
                        className="inline-block w-full px-8 placeholder-gray-900 border-0 focus:ring-transparent focus:outline-none rounded-md"
                        type="text"
                        placeholder="Drop your e-mail"
                      />
                    </div>
                    <a
                      className="inline-block w-auto bg-orange-300 hover:bg-orange-400 text-white font-bold font-heading py-2 px-8 rounded-md uppercase text-center"
                      href={() => {
                        return false;
                      }}
                    >
                      Join
                    </a>
                  </div>
                </div>
                <p className="text-gray-200">
                  The brown fox jumps over the lazy dog.
                </p>
              </div>
              <div className="w-full px-4 flex flex-wrap justify-between lg:order-last">
                <div className="w-full md:w-auto mb-4 md:mb-0 flex flex-wrap">
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/visa.svg"
                    alt=""
                  />
                  <img
                    className="mr-4 mb-2"
                    src="/yofte-assets/brands/paypal.svg"
                    alt=""
                  />
                  <img
                    className="mb-2"
                    src="/yofte-assets/brands/mastercard.svg"
                    alt=""
                  />
                </div>
                <div className="w-full md:w-auto flex">
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/facebook-white-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 mr-2 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/instagram-circle.svg"
                      alt=""
                    />
                  </a>
                  <a
                    className="inline-flex items-center justify-center w-12 h-12 rounded-full"
                    href={() => {
                      return false;
                    }}
                  >
                    <img
                      src="/yofte-assets/buttons/twitter-circle.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="pt-15   flex items-center justify-center">
              <a
                className="inline-block mr-4 text-white text-2xl font-bold font-heading"
                href="/"
              >
                <img
                  className="h-7"
                  src={`/yofte-assets/logos/${lowercasenosp(
                    store
                  )}/logowhite.webp`}
                  alt=""
                  width="auto"
                />
              </a>
              <p className="inline-block text-sm text-gray-200">
                {footercopyrighttext}
              </p>
            </div>
            <br />
          </div>
        </section>
    </React.Fragment>
  )
}
