import React, { useEffect, useState } from 'react'
// import NavbarMain from '../navbarmain'

import './blogstyle.css'
import { a, Outlet, Route, Router, useLocation, useNavigate } from 'react-router-dom';
// import { Beauty } from './beauty';
// import { Fashionblog } from './Fashionblog';

// import { Component1 } from './component1';
// import { FashionOverview } from './overviewpages/fashion-overview';
// import { BeautyOverview } from './overviewpages/beauty-overview';
import { BlogNavbar } from './blognavbar';
import Footer from '../../footer';
import axios from 'axios';
export const BlogPage = (props) => {
  const { storeid, footercopyrighttext } = props;
  const { pathname } = useLocation();
  const [blogview, setBlogview] = useState(0);
  const [shortcontent, setShortContent] = useState([]);
  const [blogtitle, setBlogtitle] = useState([]);
  const [loading, setLoading] = useState(false);

  const [blog1, setBlog1] = useState(0);
  const [blog2, setBlog2] = useState(1);
  const [blog3, setBlog3] = useState(2);
  const [blog4, setBlog4] = useState(3);
  const [blog5, setBlog5] = useState(4);
  const [blog6, setBlog6] = useState(5);
  const [blog7, setBlog7] = useState(6);
  const [blog8, setBlog8] = useState(7);
  const [blog9, setBlog9] = useState(8);
  const [blog10, setBlog10] = useState(9);
  const [blog11, setBlog11] = useState(10);
  const [blog12, setBlog12] = useState(11);
  const [blog13, setBlog13] = useState(12);
  const [blog14, setBlog14] = useState(13);
  const [activeMenu, setActiveMenu] = useState(null);
  const isActive = (path) => {

  };
  const navigate = useNavigate();

  const handleBlogview = (menuName) => {
    setActiveMenu(menuName);
    setBlog1(0);
    setBlog2(1);
    setBlog3(2);
    setBlog4(3);
    setBlog5(4);
    setBlog6(5);

  };
  const handleBlogview1 = (menuName) => {
    setActiveMenu(menuName);
    setBlog1(1);
    setBlog2(2);
    setBlog3(3);
    setBlog4(4);
    setBlog5(5);
    setBlog6(6);
  };
  const handleBlogview2 = (menuName) => {
    setActiveMenu(menuName);

    setBlog1(2);
    setBlog2(3);
    setBlog3(4);
    setBlog4(5);
    setBlog5(6);
    setBlog6(7);
  };
  const handleBlogview3 = (menuName) => {
    setActiveMenu(menuName);

    setBlog1(3);
    setBlog2(4);
    setBlog3(5);
    setBlog4(6);
    setBlog5(7);
    setBlog6(8);
  };
  const handleBlogview4 = (menuName) => {
    setActiveMenu(menuName);

    setBlog7(6);
    setBlog8(7);
    setBlog9(8);
    setBlog10(9);
    setBlog11(10);
    setBlog12(11);
  };
  const handleBlogview5 = (menuName) => {
    setActiveMenu(menuName);

    setBlog7(7);
    setBlog8(8);
    setBlog9(9);
    setBlog10(10);
    setBlog11(11);
    setBlog12(12);
  };
  const handleBlogview6 = (menuName) => {
    setActiveMenu(menuName);

    setBlog7(8);
    setBlog8(9);
    setBlog9(10);
    setBlog10(11);
    setBlog11(12);
    setBlog12(13);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("storeid", "2");
      formData.append("catid1", "32");

      const headers = {
        'accesskey': 'VilvaKart2023'
      };

      const response = await axios.post("https://earth-api.vilvabusiness.com/api/blog", formData, { headers });
      const data = response.data.data;
      // const { title, short_content } = data;

      setBlogtitle(data);
      // setShortContent(short_content);
      setLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  // Debounce function to limit API requests
  const debounce = (func, delay) => {
    let timer;
    return function () {
      const context = this;
      const args = arguments;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  };

  // Fetch data when blogview changes, but debounce the function to limit requests
  const debouncedFetchData = debounce(fetchData, 500); // Adjust delay as needed

  useEffect(() => {
    // When the component mounts or blogview changes, fetch data
    debouncedFetchData();
  }, [blogview]);

  useEffect(() => {
    // When the component mounts, simulate a click on the default a ("/fashionblog")
    const defaulta = document.querySelector('[href="beauty"]');
    if (defaulta) {
      defaulta.click();
    }
  }, []);

  const handleClick = (valuve) => {
    console.log("Blog1 Value:", valuve); // Check if blog1 value is correct
    navigate("/blogview", { state: { blog1: valuve } });
  };

  return (
    <>
      {/* <NavbarMain storeid={storeid}/> */}

      <div className='' style={{ overflow: 'hidden' }}>

        <BlogNavbar />


        <div className="flex flex-wrap container mx-auto pt-10 " >
          <div className="w-full md:w-1/2 px-2 mb-2" style={{ overflow: 'hidden' }}>
            <div className="banner-1 bg-gray-800 text-white py-6 text-center " >
              <div className="mt-6 text-center">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded mr-4">Entertainment</button>
                <p className="text-sm  mt-1">December 27, 2024</p>
              </div>

            </div>

          </div>
          <div className="w-full md:w-1/2 px-2 md:px-0">
            <div className="w-full mb-1">
              <div className='bannn-2'>   <div className=" text-center">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded mr-4">Entertainment</button>
                <p className="text-sm text-white pb-2 mt-1">December 27, 2024</p>
              </div> </div>

            </div>
            <div className="flex flex-wrap ">
              <div className="w-full md:w-1/2 mb-1">
                <div className='bannn-3 mr-1'>   <div className=" text-center">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded mr-4">Entertainment</button>
                  <p className="text-sm  text-white pb-2 mt-1">December 27, 2024</p>
                </div> </div>

              </div>
              <div className="w-full md:w-1/2 ">
                <div className='bannn-4'>   <div className=" text-center">
                  <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-4 rounded mr-4">Entertainment</button>
                  <p className="text-sm text-white pb-2 mt-1">December 27, 2024</p>
                </div> </div>

              </div>
            </div>
          </div>
        </div>



        {/* <section>
    <div className="flex container mx-auto">
        <div className="flex-1 px-8 pt-8"> 
        <div className="d-flex items-center justify-between blogcatmenu" >
            <div className=" bg-gray-500 ">LATEST ARTICLES</div>

            
<div className="flex space-x-4">
  <a href="/allblogs" className={` ml-4  ${isActive('/allblogs') ? 'text-black' : 'text-gray-100'}`}>All</a>
  <a href="/fashionblog" className={` ml-4  ${isActive('/fashionblog') ? 'text-black' : 'text-gray-100'}`}>Fashion</a>
  <a href="/beauty" className={` ml-4  ${isActive('/beauty') ? 'text-black' : 'text-gray-100'}`}>Beauty</a>
  <a href="/bollywood" className={` ml-4  ${isActive('/bollywood') ? 'text-black' : 'text-gray-100'}`}>Bollywood</a>
  <a href="/general" className={` ml-4  ${isActive('/general') ? 'text-black' : 'text-gray-100'}`}>General</a>
</div>
    </div>
    <div>   <Outlet /></div>
        </div>out
        <div className="flex-2 bg-green-200"> 
             Content for the second column goes here 
        </div>

    </div>
    </section> */}

        <div class="flex container pt-4 mx-auto">
          <div class="w-2.5/3"> <div className="flex container mx-auto">
            <div className="">
              <div className="d-flex items-center justify-between blogcatmenu" >
                <div className=" bg-gray-500 ">LATEST ARTICLES</div>


                <div className="">
                  {/* <a  className={` ml-2 md:ml-4  ${isActive('/allblogs') ? 'text-black' : 'text-gray-100'}`}>All</a> */}
                  <a onClick={() => handleBlogview('fashion')} style={{ cursor: 'pointer' }} className={`ml-4 cursor-pointer ${activeMenu === 'fashion' ? 'mininav' : 'mininav2'}`} >Fashion</a>
                  <a onClick={() => handleBlogview1('beauty')} style={{ cursor: 'pointer' }} className={`ml-4 cursor-pointer ${activeMenu === 'beauty' ? 'mininav' : 'mininav2'}`}>Beauty</a>
                  <a onClick={() => handleBlogview2('bollywood')} style={{ cursor: 'pointer' }} className={`ml-4 cursor-pointer ${activeMenu === 'bollywood' ? 'mininav' : 'mininav2'}`}>Bollywood</a>
                  <a onClick={() => handleBlogview3('general')} style={{ cursor: 'pointer' }} className={`ml-4 cursor-pointer ${activeMenu === 'general' ? 'mininav' : 'mininav2'}`}>General</a>
                </div>
              </div>
              <div>
                <div className='container mx-auto pt-5 p-4'>
                  <h2 style={{ borderLeft: '5px solid red', fontWeight: 800 }}> BROWSING : {blogtitle[blog1]?.title}</h2 >
                  <div class="flex flex-wrap container mx-auto ">
                    <a
                      class="w-full md:w-1/2 px-4 mb-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog1 + 1)}
                    >
                      <div >
                        <img class=" mb-5" src={blogtitle[blog1]?.images} alt="Image" />
                        <h2 class=" text-xl font-bold mb-2"> {blogtitle[blog1]?.title}</h2>
                        <p class="text-sm text-gray-900 mb-2">May 7, 2023 - By Tamiltshirts</p>
                        <p dangerouslySetInnerHTML={{ __html: blogtitle[blog1]?.short_content }} />
                      </div>
                    </a>
                    <a
                      class="w-full md:w-1/2 px-4 mb-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog2 + 1)}
                    >

                      <div >
                        <img class=" mb-5" src="https://images.bewakoof.com/image/content/2024/03/27005601/best-anime-of-all-time.png" alt="Image" />
                        <h2 class="text-xl font-bold mb-2"> {blogtitle[blog2]?.title}</h2>
                        <p class="text-sm text-gray-900 mb-2">May 7, 2023 - By Tamiltshirts</p>
                        <p dangerouslySetInnerHTML={{ __html: blogtitle[blog2]?.short_content }} />
                      </div>
                    </a>
                  </div>

                  <div class="flex flex-wrap">
                    {/* <!-- First Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog3 + 1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[blog3]?.title}</h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                    {/* <!-- Second Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog4 + 1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[blog4]?.title}</h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="flex flex-wrap">
                    {/* <!-- First Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog5 + 1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[blog5]?.title}</h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                    {/* <!-- Second Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog6 + 1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[blog6]?.title}</h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                  </div>


                </div>

              </div>
            </div>


          </div>

            <section>
              <div className='container mx-auto mt-10'>
                <div className="flex flex-wrap">
                  {/* First Video */}
                  <div className="w-full md:w-1/2 bg-red-200 p-4">
                    <iframe
                      width="100%"
                      height="100%"
                      src="https://www.youtube.com/embed/QGJJDXxw6mw?si=gIes4ATP7laMCWCG"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin"
                      allowfullscreen
                    ></iframe>
                  </div>
                  {/* Second Column */}
                  <div className="w-full md:w-1/2 rounded-lg">
                    {/* First Row in Second Column */}
                    <div className="w-full bg-red-200 p-4 rounded-lg">
                      <iframe
                        width="100%"
                        height="100"
                        src="https://www.youtube.com/embed/QGJJDXxw6mw?si=gIes4ATP7laMCWCG"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                    {/* Second Row in Second Column */}
                    <div className="w-full bg-red-200 p-4 rounded-lg">
                      <iframe
                        width="100%"
                        height="100"
                        src="https://www.youtube.com/embed/QGJJDXxw6mw?si=gIes4ATP7laMCWCG"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                    <div className="w-full bg-red-200 p-4 rounded-lg">
                      <iframe
                        width="100%"
                        height="100"
                        src="https://www.youtube.com/embed/QGJJDXxw6mw?si=gIes4ATP7laMCWCG"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerpolicy="strict-origin-when-cross-origin"
                        allowfullscreen
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </section>







            <div class="w-2.5/3"> <div className="flex container mx-auto">
              <div className="">
                <div className="d-flex items-center justify-between blogcatmenu" >
                  <div className=" bg-gray-500 ">LATEST LEISURE</div>


                  <div className=" space-x-4">
                    <a onClick={() => handleBlogview4('ALL')} style={{ cursor: 'pointer' }} className={`ml-4 cursor-pointer ${activeMenu === 'ALL' ? 'mininav' : 'mininav2'}`} >ALL</a>
                    <a onClick={() => handleBlogview5('Travel')} style={{ cursor: 'pointer' }} className={`ml-4 cursor-pointer ${activeMenu === 'Travel' ? 'mininav' : 'mininav2'}`}>Travel</a>
                    <a onClick={() => handleBlogview6('Entertainment')} style={{ cursor: 'pointer' }} className={`ml-4 cursor-pointer ${activeMenu === 'Entertainment' ? 'mininav' : 'mininav2'}`}>Entertainment</a>

                  </div>
                </div>
                <div>

                  <div>
                    <div className='container mx-auto pt-5 p-4'>
                      <h2 style={{ borderLeft: '5px solid red', fontWeight: 800 }}> BROWSING : {blogtitle[blog7]?.title}</h2 >
                     <div class="flex flex-wrap container mx-auto ">
                     <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog7 + 1)}
                    >
                          <div class=" px-4 mb-5">
                            <img class=" mb-5" src={blogtitle[blog7]?.images} alt="Image" />
                            <h2 class="text-xl font-bold mb-2"> {blogtitle[blog7]?.title}</h2>
                            <p class="text-sm text-gray-900 mb-2">May 7, 2023 - By Tamiltshirts</p>
                            <p dangerouslySetInnerHTML={{ __html: blogtitle[blog7]?.short_content }} />
                          </div></a>
                          <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog8 + 1)}
                    >                    

                          <div class=" px-4 mb-5">
                            <img class=" mb-5" src="https://images.bewakoof.com/image/content/2024/03/27005601/best-anime-of-all-time.png" alt="Image" />
                            <h2 class="text-xl font-bold mb-2"> {blogtitle[blog8]?.title}</h2>
                            <p class="text-sm text-gray-900 mb-2">May 7, 2023 - By Tamiltshirts</p>
                            <p dangerouslySetInnerHTML={{ __html: blogtitle[blog8]?.short_content }} />
                          </div>
                          </a>
                        </div>

                           <div class="flex flex-wrap">
                    {/* <!-- First Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog9 + 1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[blog9]?.title}</h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                    {/* <!-- Second Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog10 + 1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[blog10]?.title} </h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="flex flex-wrap">
                    {/* <!-- First Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog11 + 1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[blog11]?.title} </h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                    {/* <!-- Second Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog12 + 1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[blog12]?.title} </h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                  </div>

          


                    </div>

                  </div>

                </div>
              </div>


            </div>
              <div className="d-flex items-center justify-between blogcatmenu" >
                <div className=" bg-gray-500 ">FROM BEAUTY</div></div>
              <div>
                <div className='container mx-auto pt-5 p-4'>
                  <h2 style={{ borderLeft: '5px solid red', fontWeight: 800 }}> BROWSING : {blogtitle[9]?.title}</h2 >
                  <div class="flex flex-wrap container  mx-auto  mt-10">
                  <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog13 + 1)}
                    >
                      <div class=" px-4 mb-5">
                        <img class=" mb-5" src={blogtitle[blog13]?.images} alt="Image" />
                        <h2 class="text-xl font-bold mb-2"> {blogtitle[blog13]?.title} </h2>
                        <p class="text-sm text-gray-900 mb-2">May 7, 2023 - By Tamiltshirts</p>
                        <p dangerouslySetInnerHTML={{ __html: blogtitle[blog13]?.short_content }} />
                      </div>
                      </a>
                      <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(blog14 + 1)}
                    >
                      <div class=" px-4 mb-5">
                        <img class=" mb-5" src="https://images.bewakoof.com/image/content/2024/03/27005601/best-anime-of-all-time.png" alt="Image" />
                        <h2 class="text-xl font-bold mb-2"> {blogtitle[blog14]?.title} </h2>
                        <p class="text-sm text-gray-900 mb-2">May 7, 2023 - By Tamiltshirts</p>
                        <p dangerouslySetInnerHTML={{ __html: blogtitle[blog14]?.short_content }} />
                      </div>
                      </a>
                    </div>
                    <div class="flex flex-wrap">
                    {/* <!-- First Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(18+1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[18]?.title}</h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                    {/* <!-- Second Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(19+1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[19]?.title}</h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                  </div>
                  <div class="flex flex-wrap">
                    {/* <!-- First Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(20+1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[20]?.title}</h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                    {/* <!-- Second Item --> */}
                    <a
                      class="w-full md:w-1/2   mt-5"
                      href={() => {
                        return false;
                      }}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(21+1)}
                    >
                      <div className='flex'>
                        <img class="w-24  md:w-32" style={{ width: '150px', height: '100px' }} src="https://images.bewakoof.com/image/content/2023/05/16121250/Top-Perfume-Brands-in-India-for-Men.jpg" alt="Image" />
                        <div class="ml-2">
                          <h2 class=" font-bold"> {blogtitle[21]?.title}</h2>
                          <p class="text-sm text-gray-900"> - By Tamiltshirts</p>
                        </div>
                      </div>
                    </a>
                  </div>

                </div></div>
              <div className="d-flex items-center justify-between blogcatmenu" >
                <div className=" bg-gray-500 ">LATEST FASHION</div></div>

              {/* <FashionOverview /> */}
            </div>

          </div>
          <div class="w-1.5/3 pt-10 hidden md:block  mt-5">

            <img src='https://joinourvillage.org/wp-content/uploads/2024/02/Image-78-min-768x512.jpg'>
            </img>
            <p>In the field of fashion, minimalism is one approach to embrace a sleek and fashionable…</p>

            <div className='popularposts '>
              <h5>POPULAR POSTS</h5>
              <div class="flex mb-3">
                <div class="">
                  <img src='https://images.bewakoof.com/image/content/2024/03/27005601/best-anime-of-all-time-768x432.png' />
                </div>


                <div class="content">
                  Don’t Miss The Best Anime Of All Time To Watch
                  <div>
                    <p>March 27, 2024</p>
                  </div>
                </div>
              </div>
              <div class="flex mb-3">
                <div class="">
                  <img src='https://images.bewakoof.com/image/content/2024/03/27005601/best-anime-of-all-time-768x432.png' />
                </div>


                <div class="content">
                  Don’t Miss The Best Anime Of All Time To Watch
                  <div>
                    <p>March 27, 2024</p>
                  </div>
                </div>
              </div>
              <div class="flex mb-3">
                <div class="">
                  <img src='https://images.bewakoof.com/image/content/2024/03/27005601/best-anime-of-all-time-768x432.png' />
                </div>


                <div class="content">
                  Don’t Miss The Best Anime Of All Time To Watch
                  <div>
                    <p>March 27, 2024</p>
                  </div>
                </div>
              </div>
              <div class="flex mb-3">
                <div class="">
                  <img src='https://images.bewakoof.com/image/content/2024/03/27005601/best-anime-of-all-time-768x432.png' />
                </div>


                <div class="content">
                  Don’t Miss The Best Anime Of All Time To Watch
                  <div>
                    <p>March 27, 2024</p>
                  </div>
                </div>
              </div>
            </div>
          </div>



        </div>

        <section>
          <div className='container mx-auto mt-10'>
            <div class="flex flex-wrap">
              <div class="w-full md:w-1/3 bg-red-200 p-4"><iframe width="100%" height="315" src="https://www.youtube.com/embed/QGJJDXxw6mw?si=gIes4ATP7laMCWCG" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
              <div class="w-full md:w-1/3 bg-red-200 p-4"><iframe width="100%" height="315" src="https://www.youtube.com/embed/3ytH9Fqsogg?si=c3vlB6OcnhOv9N-n" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>
              <div class="w-full md:w-1/3 bg-red-200 p-4"><iframe width="100%" height="315" src="https://www.youtube.com/embed/0CPZm66ieS0?si=-kj_Q8XF0ZMp9FRT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe></div>

            </div>
          </div>
        </section>
      </div>
      <section>
        <Footer storeid={storeid} footercopyrighttext={footercopyrighttext} />

      </section>
    </>
  )
}






