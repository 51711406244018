import React, { useState } from 'react'
// import NavbarMain from '../navbarmain'
// import Footer from '../footer'
import { BlogNavbar } from './blognavbar';
import axios from 'axios';
import Footer from '../../footer';
import { useLocation } from 'react-router-dom';


export const BlogDetaileview = (props) => {
  const [shortcontent, setShortContent] = useState([]);
  const [blogtitle, setBlogtitle] = useState([]);
  const [content, setContent] = useState([]);
  // const [blog, setShortContent] = useState([]);
  // const [blogdatas, setShortContent] = useState([]);
  const location = useLocation();
  const blog1 = location.state?.blog1;
  const {
    storeid,
    footercopyrighttext,
    categoryListData

  } = props;

  const formData = new FormData();
  formData.append("storeid", "2");
  formData.append("catid1", "32");

  // Define your headers
  const headers = {
    'accesskey': 'VilvaKart2023'
  };

  // Make the POST request using Axios
  axios.post(`https://earth-api.vilvabusiness.com/api/blog/post/${blog1}`, formData, { headers })
    .then(response => {

      // console.log(response.data.data); 
      const data = response.data.data;
      // console.log("fff",data); // Just for debugging

      // Extracting attributes and setting them to separate states
      const { title, id, short_content, content } = data;

      setBlogtitle(title);
      // setId(id);
      setShortContent(short_content);
      setContent(content);
      console.log("content :", short_content); // Just for debugging


    })
    .catch(error => {
      // Handle errors
      console.error('Error:', error);
    });



  // {blogdata.map(blog => (
  //     // setBlogDatas(blog)
  // ))}
    console.log("jfiejfe",blog1)
  return (
    <>
      <BlogNavbar />


      <div className='container flex    mx-auto mt-10 '>

        <div class="" style={{ width: '120%' }}>
          <h2 style={{ borderLeft: '5px solid red', fontWeight: '600', marginBottom: '20px', paddingLeft: '5px' }}> BROWSING : {blogtitle}</h2 >
          {/* <button>Beauty Products</button> */}

          {/* <h2 class="text-xl font-bold">{blogs.map(blog => (
        <div key={blog.id}>
          <h2>{blog.title}</h2></div>))}</h2> */}
          <div className="container mx-auto  ">
            <img className='mx-auto py-5' src={""} alt="Image" />
            <p style={{ color: 'red', fontWeight: '800' }} dangerouslySetInnerHTML={{ __html: shortcontent }} />
          </div>

          <h2 class=" font-bold mt-10 text-justify p-4"> {blogtitle}</h2>
          <p className=' py-4 text-center' dangerouslySetInnerHTML={{ __html: content }} />
        </div>


        <div className='hidden md:block' style={{ borderLeft: '0.2px solid gray', marginLeft: '30px', paddingLeft: '30px' }}> <div class="flex mb-3 ">

          <div class="">
            <img src='https://images.bewakoof.com/image/content/2024/03/27005601/best-anime-of-all-time-768x432.png' />
          </div>



        </div></div>
      </div>

      <Footer storeid={storeid} footercopyrighttext={footercopyrighttext} />
    </>
  )
}
